.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}
.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title {
  cursor: pointer;
  color: #666;
  padding: 10px;
  border: solid 1px #ccc;
  box-shadow: 0 0 2px #ccc;

  display: flex;

  align-items: center;
}
.accordion-title::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
}
.accordion-title:hover,
.accordion-title.open {
  color: black;
  background-color: #f6f8f9;
}
.accordion-title.open::after {
  content: '';
  border-top: 0;
  border-bottom: 5px solid;
}
