input[type='radio'] {
  &.my-radiobox {
    display: grid;
    place-items: center;
    width: 15px !important;
    height: 15px !important;
    padding: 0 !important;
    margin: 0;
    color: $color-secondary;
    position: relative;
    cursor: pointer;
    appearance: none;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      line-height: 1;
      color: inherit;
      border-width: 2px;
      border-style: solid;
      border-color: $color-secondary;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
    }
    &:checked {
      &:before {
        border-width: 1px;
      }
      &:after {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        background: $color-secondary;
      }
    }
    &:disabled {
      cursor: not-allowed;
      &:before {
        border-color: darkgray;
      }
      &:checked {
        opacity: 0.65;
        &:before {
          border-color: $color-secondary;
        }
      }
    }
    &.focus,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
