@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

$fontOswald: 'Oswald', sans-serif;
$fontPoppins: 'Poppins', sans-serif;

body {
  font-family: $fontPoppins !important;
}

.title-font {
  font-family: $fontOswald;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.logo-1 {
  height: 100px;
  width: 200px;
  display: inline-block;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.h-all-100 * {
  height: 100%;
}

a:hover {
  text-decoration: none !important;
}

.btn {
  padding: 10px 15px !important;
}

.btn-2 {
  padding: 10px 15px !important;
  font-family: 'Oswald', sans-serif !important;
}

.line-height-14 {
  line-height: 1.4;
}

.line-height-16 {
  line-height: 1.6;
}

.line-height-2 {
  line-height: 2;
}

.scroll-hide::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}

.map-card {
  width: 300px;
  z-index: 13;
}

.map-prop-detail-marker {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.map-overlay {
  &__button {
    display: none;
  }

  @media screen and (max-width: 650px) {
    position: fixed;
    background-color: #eeeeeeeb;
    display: block;
    padding: 100px 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    min-width: 100%;

    &__button {
      display: block;
    }
  }
}

.map-overlay-1 {
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  height: 1000vh;
  width: 170vw;
  top: 50%;
  left: 50%;
  transform: translate(-65%, -65%);
}

.tab-title {
  font-size: 13px;
  font-weight: 500;
  @media screen and (max-width: 340px) {
    font-size: 11px;
  }
}

.dropdown-item {
  font-weight: 500 !important;
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.link-line {
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: $color-primary !important;
  text-transform: uppercase;
}

.link-line:hover {
  color: $color-primary !important;
}

.link-line::after {
  content: '';
  width: 0;
  height: 2px;
  display: flex;
  background-color: currentColor;
  transition: all 0.5s ease;
}

.link-line:hover::after {
  width: 60px;
  transition: all 0.5s ease;
}

.link-line-2 {
  display: inline-block;
  cursor: pointer;
  opacity: 0.8;
}

.link-line-2.active,
.link-line-2:hover {
  color: currentColor !important;
  opacity: 1;
}

.link-line-2::after {
  content: '';
  width: 0;
  height: 2px;
  display: flex;
  background-color: currentColor;
  transition: all 0.5s ease;
}

.link-line-2.active::after,
.link-line-2:hover::after {
  width: 100%;
  transition: all 0.5s ease;
}

.pos-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

textarea {
  padding: 6px !important;
}

input {
  padding: 0 6px !important;
}

.custom-btn-primary {
  background-color: $color-light !important;
  color: $color-primary !important;
  height: 50px;
  border-radius: 3px;
  &:hover {
    background-color: $color-primary !important;
    color: $color-white !important;
  }
}

.progress {
  height: 6px !important;
}

.pos-r {
  position: relative;
  z-index: 3;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 50px;
}

// ===

.featured-overlay-1 {
  position: absolute;
  // right: 0;
  // top: 0;
  z-index: 3;
  background-color: $color-secondary;
  color: $color-icon;
  font-size: 10px;
  font-weight: 500;
  padding: 5px 12px;
  margin: 13px;
  border-radius: 4px;
}

.featured-overlay-2 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  color: $color-icon;
  background-color: $color-primary;
  font-size: 10px;
  font-weight: 500;
  padding: 5px 12px;
  margin: 13px;
  border-radius: 4px;
}

// ===
.ads-stop {
  position: relative;
  width: 100%;
}

.ads-move {
  position: fixed;
  top: 60px;
  width: 250px;
  height: 260px;
}

.sitebar {
  width: 330px;
}

.sidebar-move {
  position: fixed;
  top: 55px;
  width: 330px;
  padding-bottom: 80px;
  transition: all 0.5s ease;
  background-color: $color-white;
  height: 100%;
}

.sidebar-not-move {
  transition: all 0.5s ease;
  width: 330px;
}

@media screen and (max-width: 1318px) {
  .sitebar,
  .sidebar-move,
  .sidebar-not-move {
    width: 280px;
  }
}

@media screen and (max-width: 1120px) {
  .sitebar,
  .sidebar-move,
  .sidebar-not-move {
    width: 250px;
  }
}

.mobile-data-show {
  display: none;
}

.mobile-card {
  .card-item-img {
    height: 200px;
    width: 100%;
  }
  .mobile-data-show,
  .mobile-items-show {
    display: none;
  }
  .mobile-data-hide {
    display: block;
  }

  @media screen and (max-width: 600px) {
    .featured-overlay-1,
    .featured-overlay-2 {
      display: none !important;
    }
    .card-item-img {
      height: 100%;
    }
    .details {
      justify-content: center !important;
    }
    .mobile-data-show {
      display: block;
    }
    .mobile-data-hide,
    .mobile-items-hide {
      display: none;
    }
    .mobile-items-show {
      display: flex;
      flex-direction: column;
    }
  }
}

// ===
.page-link {
  border-radius: 50px;
  color: $color-black !important;
  border: 0 !important;
}

.page-item.active .page-link {
  color: $color-white !important;
}

// ===
.single-pricing {
  *,
  *:hover {
    transition: all 0.5s ease;
  }
  &:hover {
    .pricing-card {
      &__header {
        background-color: $color-primary;
        .title,
        .title-price {
          color: $color-white;
        }
      }
      .custom-btn-primary {
        background-color: $color-primary !important;
        color: $color-white !important;
      }
    }
  }
  .pricing-card {
    &__header {
      background-color: $color-light;
      padding: 40px 20px;
      .title {
        color: $color-primary;
      }
      .title-price {
        color: $color-secondary;
      }
    }
    ul {
      font-weight: 500;
      list-style-image: url('../images/check.svg');
      text-align: left;
      padding: 0 20px;
      align-items: center;
      li {
        width: 65%;
      }
    }
  }
}

// Mobile Sidebar
.mobile-sidebar {
  background-color: #0000006e;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1040;
  &__container {
    position: relative;
    z-index: 1050;
    height: 100%;
    width: 250px;
  }
}

// Sidebar
.sidebar-container {
  height: 100%;
  box-shadow: 0 0 5px 0px #0000002b;
  &__box {
    background-color: $color-white;
    width: 250px;
    height: 100%;
    padding: 20px;
    .nav-item {
      margin: 7px 0;
      a {
        padding: 10px;
        border-radius: 4px;
        display: block;
        width: 100%;
        color: $color-secondary;
        &.active,
        &:hover {
          background-color: $color-primary !important;
          color: $color-white;
          i {
            color: $color-white;
          }
        }
      }
    }
    &-item-container {
      font-size: 14px;
      font-weight: 500;
      i {
        color: $color-primary;
      }
    }
  }
}

.sidebar-button {
  display: none;
}

@media screen and (max-width: 1240px) {
  .sidebar-button {
    display: block;
  }
  .sidebar-container {
    position: absolute;
    top: 0;
    padding-top: 30px;
    z-index: 40;
  }
}

.list-card-container {
  position: relative;
  &:focus,
  &:hover {
    .list-card-image-size {
      .list-card-overlay {
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
  }
  .list-card-image-size {
    position: relative;
    height: 250px;
    min-height: 250px;
    width: 250px;
    min-width: 250px;
    .list-card-items {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
    .list-card-overlay {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      &__item {
        position: relative;
        z-index: 30;
      }
    }
  }
  .list-card-image-size img {
    object-fit: cover;
    box-shadow: 0 0 5px #8d8686;
  }
  @media only screen and (max-width: 1000px) {
    .list-card-image-size {
      height: 60vmin;
      min-height: 60vmin;
      width: 100%;
      min-width: 100%;
    }
    .list-card-image-size img {
      object-fit: cover;
      background-color: $color-light;
    }
  }
}

.tabs-buttons {
  a {
    border: 1px solid transparent;
    border-radius: 50px !important;
    background-color: $color-icon !important;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: $color-primary !important;
      color: $color-white !important;
    }
  }
}

.pos-box-fix {
  position: fixed;
  width: 100%;
  left: 50%;
  top: 55px;
  transform: translateX(-50%);
  background: #f7f7f7;
  padding: 5px;
  z-index: 1040;
}

.modal-content {
  border-radius: 1px !important;
}

@media screen and (max-width: 500px) {
  .modal {
    display: flex !important;
    flex-direction: column;
  }
  .modal-content {
    border-radius: 3px;
  }
  .modal-rounded-top {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
}

.bg-image-cover {
  background-size: cover;
  background-position: center;
}

.bg-image-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-size {
  min-height: 350px;
  @media screen and (max-width: 400px) {
    min-height: 250px;
  }
}

.pos-box-fix-2 {
  position: sticky;
  z-index: 1040;
  top: 20px;
  background-color: #fff;
  // height: 580px;
}

.font-container-text {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  color: #a0a0a0;
  @media screen and (max-width: 500px) {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 27px;
  }
}

.new-dp-show {
  z-index: 1050;
  opacity: 1;
}

.new-dp-hide {
  z-index: -10;
  opacity: 0;
}

.new-dp {
  position: absolute;
  right: 0;
  width: 220px;
  text-align: center;
  background-color: $color-white;
  box-shadow: 0 0 2px rgb(115, 115, 115);
  border-radius: 3px;

  a {
    display: block;
    padding: 8px;
    border-bottom: 1px solid rgb(214, 213, 213);
    color: $color-black !important;
    &.active,
    &:hover {
      color: $color-primary;
    }
    &::after {
      display: none;
    }
  }
}

.remove-line {
  &::after {
    display: none !important;
  }
}

// ===

.form-hero {
  height: 100vh;
  @media screen and (max-width: 1070px) {
    height: 50vh;
  }
  @media screen and (max-width: 1000px) {
    height: 38vh;
  }
}

.mobile-vh-100 {
  @media screen and (max-width: 576px) {
    height: 100vh;
  }
}

.react-multi-carousel-list {
  overflow-x: clip !important;
  overflow: unset;
}

.carousel-button-group {
  position: absolute;
  top: -35px;
  right: 0;
}

.top-50 {
  top: 0px;
}

.pos-arrow {
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
}

.text-shadow {
  text-shadow: 2px 2px #000;
}

// ===

.regional-partners {
  .react-multiple-carousel__arrow {
    background: unset !important;
    min-width: 70px !important;
    width: 70px;
    min-height: 70px !important;
    height: 70px;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 40px !important;
    color: #9bacc5 !important;
  }
}
