html {
  height: 100%;
  overflow: hidden;
}

.gm-style-cc:last-child {
  display: none !important;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

a[title='Report errors in the road map or imagery to Google'] {
  display: none !important;
}

body {
  overflow: auto;
  height: 100%;
}

.gmnoprint {
  display: none !important;
}

.gmnoprint:last-child {
  display: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.rdw-editor-main {
  padding: 10px;
  min-height: 200px;
  border: 1px solid #d4d4d4 !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container-terms section {
  padding-top: 7rem;
  margin-top: -5rem;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  box-shadow: unset !important;
  outline: unset !important;
}

.box-shadow-primary {
  box-shadow: inset 0 1px 8px rgb(0 0 0 / 16%),
    inset 0 0.25px 2px rgb(0 0 0 / 32%);
}

.new-property-container .btn-link {
  color: #8d8686;
  text-decoration: none;
}

.new-property-container .btn-link:hover {
  color: #951b58;
}

.text-yellow {
  color: var(--main-color-one) !important;
}

.dropdown-select:disabled {
  color: #aaaaaa !important;
}

.btn-white:hover,
.btn-white:focus {
  color: #fff !important;
}

.link-blue-hover:hover {
  color: #97a8c5 !important;
}

.btn-primary,
.btn-primary:hover {
  background: var(--main-color-one) !important;
  color: #fff !important;
}

.spinner-custom {
  overflow: hidden !important;
  padding-top: 100px !important;
}

.rld-banner-tab .nav-item a:hover,
.rld-banner-tab .nav-item a:focus,
.rld-banner-tab .nav-item a.active {
  color: #fff !important;
}

a:hover {
  text-decoration: none;
  opacity: 0.8;
}

.navbar a,
.footer-area a {
  transition: all 0.5s ease;
}

.navbar a,
.navbar a:hover,
.navbar a:focus,
.navbar a:active,
.footer-area a,
.footer-area a:hover,
.footer-area a:focus,
.footer-area a:active {
  text-decoration: none !important;
  outline: none !important;
  color: #fff;
  font-weight: 600 !important;
}

.navbar-area-2 a,
.navbar-2 a {
  color: #000 !important;
}

.navbar-area-2 a:hover,
.navbar-2 a:hover,
.navbar-area-2 a.active,
.navbar-2 a.active {
  color: #3b80d2 !important;
}

/* .navbar-area-2 a::after {
  background-color: currentColor !important;
} */

.navbar a:hover,
.navbar a:focus,
.navbar a.active,
.footer-area a:hover,
.footer-area a:focus,
.footer-area a.active,
.breadcrumb-item.active {
  color: #fff;
  font-weight: 600;

  transition: all 0.5s ease;
}

.navbar a::after,
.footer-area a::after {
  content: '';
  width: 0;
  height: 2px;
  display: flex;
  background-color: currentColor;
  transition: all 0.5s ease;
}

.navbar a:focus::after,
.navbar a.active::after,
.footer-area a:focus::after,
.footer-area a.active::after {
  width: 35px;
  transition: all 0.5s ease;
}

.rounded-xl {
  border-radius: 1rem !important;
}

.rounded-left-xl {
  border-top-left-radius: 1rem !important;
}

.rounded-right-xl {
  border-top-right-radius: 1rem !important;
}

ol li:before {
  display: none;
  /* counter-increment: counter;
  content: counter(counter);
  font-weight: 500;
  margin-right: 10px; */
}

/* == */

.item-size-img {
  height: 300px;
  width: 100%;
}

@media only screen and (max-width: 650px) {
  .item-size-img {
    height: 200px;
  }
}

.carousel .slide img {
  border-radius: 5px;
}

.carousel .thumb {
  border: 0 !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border-radius: 0px;
  border: 0 !important;
}

.carousel .thumb img {
  border-radius: 5px;
  height: 60px !important;
}

.carousel .thumbs-wrapper {
  margin: 10px 0 !important;
}

.filters-map {
  position: absolute;
  right: 0;
  top: 1px;
  width: 18px;
  margin-right: -3px;
  color: white !important;
  height: 18px;
  border-radius: 50%;
}

.filters {
  position: absolute;
  right: 0;
  top: 21px;

  width: 18px;
  margin-right: -10px;
  color: white !important;
  height: 18px;
  border-radius: 50%;
}

.carousel .thumbs {
  padding: 0;
}

.carousel .control-arrow {
  top: 40% !important;
  background-color: #000 !important;
  border-radius: 50px;
  font-size: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 5px solid transparent !important;
  border-bottom: 5px solid transparent !important;
}

/* === */

.custom-width {
  width: 250px !important;
  top: 40px !important;
  transform: translateX(-85%) !important;
}

.custom-width-2 {
  width: 250px !important;
  top: 47px !important;
  transform: unset !important;
}
/* .custom-width-2:before {
  content: '';
  position: absolute;
  right: 11px;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #f7f7f7 transparent;
  z-index: 9999;
} */

@media only screen and (max-width: 1000px) {
  .custom-width {
    top: 40px !important;
  }

  .custom-width-2 {
    width: 200px !important;
    top: 30px !important;
    transform: translateX(-55%) !important;
  }
}

.list-show li {
  transition: all 0.5s ease;
  height: 25px;
  opacity: 1;
}

.list-hide li {
  transition: all 0.5s ease;
  height: 0px;
  opacity: 0;
}

.footer-dropdown-show {
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  opacity: 1;
  flex-direction: column;
  position: absolute;
  bottom: 40px;
  right: 0;
  background-color: #fff;
  width: 180px;
  padding: 10px 10px;
  border-radius: 11px;
  box-shadow: 0 0 2px;
  margin: 5px;
}

.footer-dropdown-hide {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  bottom: 40px;
  right: 0;
  opacity: 0;
  z-index: -10;
  width: 180px;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 1050px) {
  .single-service .readeal-top {
    height: 140px;
    display: flex;
    flex-direction: column;
  }

  .single-service .readmore-btn {
    align-self: center;
    margin-top: auto;
  }
}

@media only screen and (max-width: 632px) {
  .single-service .readeal-top {
    height: unset;
  }
}

/* ==== */

.new-property-container .nav-link {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 0 5px #adb5bd;
  transition: all 0.2s ease;
  cursor: pointer;
}

.new-property-container .nav-link.active {
  box-shadow: 0 0 5px #951b58;
}

.new-property-container .nav-link:nth-child(-n + 4) {
  margin-right: 20px;
  margin-bottom: 20px;
}

.new-property-container .nav-link img {
  height: 90%;
  width: 90%;
  object-fit: contain;
}

.custom-search-results img {
  height: 100%;
  width: 100%;
}

.custom-search-results .details {
  flex: 0 0 60% !important;
}

@media only screen and (max-width: 765px) {
  .custom-search-results .details {
    flex: 0 0 100% !important;
  }

  .custom-search-results .details .feature-logo {
    left: 10px !important;
    top: -24px !important;
  }
}

.custom-filter-2 {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.nav-tabs {
  border: 0 !important;
  justify-content: center;
  margin-bottom: 15px;
}

/* == */

.text-underline {
  text-decoration: underline !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-black-50 {
  color: #696969 !important;
}

.z-index-overlay {
  z-index: 30;
  box-shadow: 0px 10px 50px rgb(13 38 59 / 10%);
  border-radius: 0px 0px 3px 3px;
}

.display-3 {
  font-weight: bold !important;
  font-size: 60px !important;
  line-height: 76px !important;
}

@media (max-width: 735px) {
  .display-3 {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 38px !important;
  }
}

h5,
.h5 {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 28px !important;
}

h6,
.h6 {
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 17px;
}

.pos-input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

/* == */

.react-multi-carousel-dot button {
  border-color: var(--main-color-one) !important;
  background-color: var(--main-color-one);
  width: 8px !important;
  height: 8px !important;
}

.react-multi-carousel-dot--active button {
  background: #fff !important;
  border-color: var(--main-color-one) !important;
  width: 9px !important;
  height: 9px !important;
}

.react-multiple-carousel__arrow {
  background: #fff !important;
  min-width: 30px !important;
  min-height: 30px !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 9px !important;
  font-weight: 600 !important;
  color: var(--heading-color) !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

/* === */

.dropdown-item {
  padding: 4px 0;
  font-weight: 600;
  font-size: 10px !important;
  color: var(--heading-color) !important;
  text-transform: uppercase;
  background-color: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.dropdown-item.active,
.dropdown-item:hover {
  color: var(--main-color-one) !important;
  transition: all 0.5s ease;
}

/* == */

.single-intro {
  margin-bottom: 30px;
  transition: all 0.5s ease;
  border-radius: 4px;
  box-shadow: 0 0 2px 0.5px #a9a6a600;
}
.single-intro .thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  margin: 0 auto;
  border-radius: 50px;
  line-height: 68px;
  text-align: center;
  margin-bottom: 25px;
  transition: all 0.2s ease;
}
.single-intro:hover {
  transition: all 0.5s ease;
  transform: scale(1.01);
  box-shadow: 0 0 2px 0.5px #a9a6a65f;
}

.single-intro:hover .thumb {
  background: var(--main-color-one);
  transition: all 0.2s ease;
}

.single-intro:hover .thumb img {
  filter: brightness(0) invert(1);
  transition: all 0.1s ease;
}
.single-intro .thumb img {
  width: auto;
  display: inline-block;
  transition: all 0.3s ease;
}
.single-intro .text {
  font-size: 45px;
  font-family: var(--heading-font);
  font-weight: 700;
  color: var(--main-color-one);
}
.single-intro .details .title {
  margin-bottom: 15px;
  font-weight: 700;
}
.single-intro .details p {
  margin-bottom: 0;
}
.single-intro .details .read-more {
  font-weight: 600;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding-top: 12px;
  display: block;
}
.single-intro .details .read-more:hover {
  color: var(--main-color-one);
  text-decoration: underline;
}
.single-intro.style-two .thumb {
  border-radius: 50%;
  font-size: 26px;
  font-weight: 500;
  font-family: var(--heading-font);
  color: #fff;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-4-9 0:29:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.slide-in-left {
  -webkit-animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
