/* make the customizations */
$theme-colors: (
  'primary': #0061df,
  'secondary': #0d263b,
  'info': #a3bcdb,
  'danger': #ee4343,
  'warning': #eec843,
  'success': #43eea6,
  'light': #f6f4f3,
  'primary-2': #0096ae,
  'blue': #009ee3,
  'lightblue': #7dbfc6,
  'orange': #f39200,
  'black': #1d1d1d,
);

$color-white: #fff;
$color-primary: #0061df;
$color-light: #f6f4f3;
$color-secondary: #0d263b;
$color-black: #000;
$color-icon: #edf4fd;
$color-grey: #f6f4f3;
$Orange: #f39200;

.btn-primary {
  color: $color-icon !important;
  background: $color-primary !important;
}

.bg-primary-2-70 {
  background-color: #0097aec2 !important;
}

.bg-icon {
  background-color: $color-icon;
}

.text-green {
  color: #125e2b;
}

.bg-faqs-text {
  background-color: #ebe6e3;
}

.bg-arrow {
  background-color: #8298b7;
}

.bg-grey {
  background-color: $color-grey;
}

.bg-black {
  background-color: $color-black;
}

.input-group-text {
  background-color: $color-light !important;
  border: 0 !important;
}

.slick-dots li button:before {
  color: $color-primary !important;
}

.btn-success:hover,
.btn-outline-success:hover {
  color: $color-white !important;
}

.react-toggle--checked .react-toggle-thumb {
  border-color: $color-primary !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: $color-primary !important;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  opacity: 0.8;
}

a {
  h5 {
    color: $color-secondary !important;
    &:hover {
      opacity: 0.8;
    }
  }
}
